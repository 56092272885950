;(($, window, document, undefined) => {
    let validCode = true;
    let time = 60;
    $.extend(window.yunu, {
        order_add(){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <div class="layui-form-item">
                        <label class="layui-form-label">所属分组</label>
                        <div class="layui-input-inline w334" id="order_group_input">
                            <input type="hidden" name="group_id" value="0" />
                            <div class="layui-form-mid mr50">无分组</div>
                            <div class="layui-form-mid ml50">
                                <a class="color" href="javascript:;" onclick="yunu.group()">分组管理</a>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">网址</label>
                        <div class="layui-input-inline" style="width: 60px;">
                            <input class="layui-input idis" type="text" placeholder="http(s)" value="http" disabled>
                        </div>
                        <div class="layui-form-mid">://</div>
                        <div class="layui-input-inline" style="width: 60px;">
                            <input class="layui-input idis" type="text" placeholder="www(顶级域名输入*)" value="www" disabled>
                        </div>
                        <div class="layui-form-mid">.</div>
                        <div class="layui-input-inline" style="width: 200px;">
                            <input class="layui-input" type="text" name="domain" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词</label>
                        <div class="layui-input-block mr50">
                            <textarea name="keywords" class="layui-textarea" placeholder="关键词一行一个"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">搜索引擎</label>
                        <div class="layui-input-block">
                            <input type="radio" name="search_engine" value="1" title="百度" checked>
                            <input type="radio" name="search_engine" value="5" title="好搜">
                            <input type="radio" name="search_engine" value="3" title="搜狗">
                            <input type="radio" name="search_engine" value="2" title="百度移动">
                            <input type="radio" name="search_engine" value="6" title="好搜移动">
                            <input type="radio" name="search_engine" value="4" title="搜狗移动">
                            <input type="radio" name="search_engine" value="7" title="神马">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '新增任务',
                    area: ['780px','450px'],
                    content: tpl(),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/yunkuaipai/add.html',
                                field: data.field,
                                success: function (res) {
                                   /* layer.close(index);*/
                                    yunu.msg(res.info);
                                    window.location.href = '/' + module_name + '/yunkuaipai/add_review.html';
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        order_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">所属分组</label>
                        <div class="layui-input-inline w334" id="order_group_input">
                            <input type="hidden" name="group_id" value="${data.group_id}" />
                            <div class="layui-form-mid mr50">${data.group_name}</div>
                            <div class="layui-form-mid ml50">
                                <a class="color" href="javascript:;" onclick="yunu.group()">分组管理</a>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '修改分组',
                    area: ['780px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/yunkuaipai/edit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                    obj.update(res.data);
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        group(callback){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '分组管理',
                    area: ['780px', '350px'],
                    content: '<table id="order_group" lay-filter="order_group"></table>',
                    success: function (layero, index) {
                        yunu.table({
                            elem: '#order_group',
                            url: '/' + module_name + '/group/index.html',
                            skin: 'nob',
                            cols: [[
                                {field: 'title', title: '分组', align: 'center', edit: 'text'}
                                ,{title: '操作', align: 'center', templet: function (d) {
                                    if (d.id) {
                                        var el = '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="order_group_check">选用</button>';
                                        el += '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="order_group_edit">修改</button>';
                                        el += '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="order_group_del">删除</button>';
                                    } else {
                                        var el = '<button type="button" class="layui-btn layui-btn-normal layui-btn-xs" lay-event="order_group_add">新增</button>';
                                    }
                                    return el;
                                }}
                            ]],
                            done: function () {
                                yunu.layui_table_redraw('order_group', index);
                            }
                        }, {
                            table: 'order_group',
                            event: {
                                order_group_check: function (data, obj) {
                                    $('#order_group_input').find('input').val(data.id).next().html(data.title);
                                    layer.close(index);
                                    if (callback) {
                                        callback(data.id);
                                    }
                                },
                                order_group_edit: function (data, obj) {
                                    if (typeof(data.title) == "undefined") {
                                        yunu.msg('分组名称不能为空', 'error');
                                        $(obj.tr).find('td').eq(0).click();
                                        return false;
                                    }
                                    yunu.post({
                                        url: '/' + module_name + '/group/edit.html',
                                        field: {
                                            id: data.id,
                                            title: data.title
                                        },
                                        success: function (res) {
                                            yunu.msg(res.info);
                                        }
                                    });
                                },
                                order_group_del: function (data, obj) {
                                    yunu.del({
                                        content: '是否确认删除',
                                        url: '/' + module_name + '/group/del.html',
                                        id: data.id,
                                        success: function (res) {
                                            obj.del();
                                        }
                                    });
                                },
                                order_group_add: function (data, obj) {
                                    if (typeof(data.title) == "undefined") {
                                        yunu.msg('分组名称不能为空', 'error');
                                        $(obj.tr).find('td').eq(0).click();
                                        return false;
                                    }
                                    yunu.post({
                                        url: '/' + module_name + '/group/add.html',
                                        field: {
                                            title: data.title
                                        },
                                        success: function (res) {
                                            yunu.msg(res.info);
                                            table.reload('order_group');
                                        }
                                    });
                                }
                            }
                        });
                    }
                });
            });
        },
        package_renew(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号：</label>
                        <div class="layui-form-mid">${data.account}</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">当前套餐：</label>
                        <div class="layui-form-mid">${data.package_name}</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">到期时间：</label>
                        <div class="layui-form-mid">${data.endtime_format}</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">续费金额：</label>
                        <div class="layui-form-mid"><span class="c_30aa44">${data.renew_price}</span> 元</div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">备注：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="note">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确认续费</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                yunu.post({
                    url: '/' + module_name + '/yunwanci/get_user_packages.html',
                    field: {id: data.id},
                    success: function (res) {
                        data.renew_price = res['price'];
                        layer.open({
                            type: 1,
                            title: '续费',
                            area: ['631px'],
                            content: tpl(data),
                            success: function (layero, index) {
                                form.render();
                                form.on('submit(*)', function(data){
                                    yunu.post({
                                        url: '/' + module_name + '/yunwanci/renew.html',
                                        field: data.field,
                                        success: function (res) {
                                            layer.close(index);
                                            yunu.msg(res.info, {
                                                end: function () {
                                                    window.location.reload();
                                                }
                                            });
                                        }
                                    });
                                    return false;
                                });
                            }
                        });
                    }
                });
            });
        },
        user_add(agent,oem_domain){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">账号</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="username" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">密码</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="password" name="password" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">QQ</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="qq" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">手机</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input tel" type="text" name="tel" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">验证码</label>
                    <div class="layui-input-inline w200">
                        <input class="layui-input yzcode" style="width:200px" type="text" name="yzcode" lay-verify="required">
                    </div>
                    <div class="layui-input-inline w150">
                        <input class="layui-btn getyzcode" type="button" onclick="yunu.sendsms($(this))" value="获取验证码">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">所属员工</label>
                    <div class="layui-input-inline w334">
                        <select name="staff_id" xm-select="staff_id" xm-select-search="" xm-select-radio>
                            <option value="">请选择</option>
                        </select>
                    </div>
                </div>
                ${agent == '1' ?
                `
                <div class="layui-form-item isagent">
                    <label class="layui-form-label">开代理</label>
                    <div class="layui-input-inline w334">
                        <input type="radio" name="isagent" value="0" title="不允许" checked>
                        <input type="radio" name="isagent" value="1" title="允许" >
                    </div>
                </div>
                ` : ` ` }

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/static/public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;
                if (oem_domain == "") {
                    layer.msg("未设置代理域名，无法添加子用户", {icon: 2});
                    return false;
                };
                layer.open({
                    type: 1,
                    title: '新增用户',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        layui.formSelects.data('staff_id', 'server', {
                            url: '/' + module_name + '/Api/staff_id'
                        });
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/user/add.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        user_edittel(){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">手机</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input tel" type="text" name="tel" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">验证码</label>
                    <div class="layui-input-inline w200">
                        <input class="layui-input yzcode" style="width:200px" type="text" name="yzcode" lay-verify="required">
                    </div>
                    <div class="layui-input-inline w150">
                        <input class="layui-btn getyzcode" type="button" onclick="yunu.sendsms($(this))" value="获取验证码">
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '修改手机号',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/user/edittel.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        sendsms(obj){
            let telval = $(".tel").val();
            if (telval == "") {
                layer.msg('请输入手机号', {icon: 2});
                return false;
            }
            if (!telval.match(/^((1[0-9]{10}))$/)) {
                layer.msg('请输入正确手机号', {icon: 2});
                return false;
            }

            let $code = obj;
            if (validCode) {
                yunu.post({
                    url: '/' + module_name + '/login/smscode.html',
                    field: {'tel':telval,'c':1},
                    success: function (res) {
                        if (res.state == 1) {
                            layer.msg(res.info, {icon: 1});
                            validCode = false;
                            let t = setInterval(function  () {
                                time--;
                                $code.val(time+"秒");
                                if (time == 0) {
                                    clearInterval(t);
                                    $code.val("重新获取");
                                    validCode = true;
                                }
                            },1000)
                            if (res.yzcode) {
                                $('[name="yzcode"]').val(res.yzcode)
                            }
                        }else{
                            layer.msg(res.info, {icon: 2});
                        }
                    }
                });
            }
        },
        user_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">账号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input layui-disabled idis" type="text" value="${data.username}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">密码</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="password" name="password">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">QQ</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="qq" value="${data.qq}"  required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">手机</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input layui-disabled idis" type="text" value="${data.tel}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">公司名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="cname" value="${data.cname != null ? data.cname : ''}" >
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">所属员工</label>
                        <div class="layui-input-inline w334">
                            <select name="staff_id" xm-select="staff_id" xm-select-search="" xm-select-radio>
                                <option value="">请选择</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">开代理</label>
                        <div class="layui-input-inline w334">
                            ${data['isagent'] == '1' ?
                            `
                            <input type="radio" name="isagent" value="0" title="不允许" >
                            <input type="radio" name="isagent" value="1" title="允许" checked>
                            ` :
                            `
                            <input type="radio" name="isagent" value="0" title="不允许" checked>
                            <input type="radio" name="isagent" value="1" title="允许" >
                            ` }
                        </div>
                    </div>
                    <!-- <div class="layui-form-item">
                        <label class="layui-form-label">快排风控</label>
                        <div class="layui-input-inline w334">
                            ${data['kuaipairisk'] == '1' ?
                            `
                            <input type="radio" name="kuaipairisk" value="0" title="禁用" >
                            <input type="radio" name="kuaipairisk" value="1" title="启用" checked>
                            ` :
                            `
                            <input type="radio" name="kuaipairisk" value="0" title="禁用" checked>
                            <input type="radio" name="kuaipairisk" value="1" title="启用" >
                            ` }
                        </div>
                    </div> -->
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/static/public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;

                layer.open({
                    type: 1,
                    title: '详细设置',
                    area: ['580px',"540px"],
                    content: tpl(data),
                    success: function (layero, index) {
                        layui.formSelects.data('staff_id', 'server', {
                            url: '/' + module_name + '/Api/staff_id?id=' + data.staff_id
                        });
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/user/edit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        product_add(){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">产品名称</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="title" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">分组别名</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="groupname" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">状态</label>
                    <div class="layui-input-inline w334">
                        <input type="radio" name="state" value="0" title="禁用" >
                        <input type="radio" name="state" value="1" title="启用" checked>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">描述</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="remark">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">排序</label>
                    <div class="layui-input-inline w50">
                        <input class="layui-input" type="number" name="sort" value="0'">
                    </div>
                </div>

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '新增产品',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/product/add.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        product_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />

                    <div class="layui-form-item">
                        <label class="layui-form-label">产品名称</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="title" value="${data.title}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">分组别名</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="groupname" value="${data.groupname}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">状态</label>
                        <div class="layui-input-inline w334">
                            ${data['state'] == '1' ?
                            `
                            <input type="radio" name="state" value="0" title="禁用" >
                            <input type="radio" name="state" value="1" title="启用" checked>
                            ` :
                            `
                            <input type="radio" name="state" value="0" title="禁用" checked>
                            <input type="radio" name="state" value="1" title="启用" >
                            ` }
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">描述</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" value="${data.remark}" name="remark">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">排序</label>
                        <div class="layui-input-inline w50">
                            <input class="layui-input" type="number" value="${data.sort}" name="sort" value="0'">
                        </div>
                    </div>

                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '编辑产品',
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/product/edit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        setmeal_add(pid,ptitle){
            let tpl = `<form class="layui-form pt25 pb20">
                <input type="hidden" name="pid" value="${pid}">
                <div class="layui-form-item">
                    <label class="layui-form-label">产品名称</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input layui-disabled" type="text" value="${ptitle}" disabled>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐名称</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="title" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐价格</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="price" placeholder="￥" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">状态</label>
                    <div class="layui-input-inline w334">
                        <input type="radio" name="state" value="0" title="禁用" >
                        <input type="radio" name="state" value="1" title="启用" checked>
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">描述</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="remark">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">排序</label>
                    <div class="layui-input-inline w50">
                        <input class="layui-input" type="number" name="sort" value="0'">
                    </div>
                </div>

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '新增套餐',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/product/setmealadd.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        setmeal_edit(data, obj){
            let tpl = `<form class="layui-form pt25 pb20">
                <input type="hidden" name="id" value="${data.id}" />
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐名称</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="title" value="${data.title}" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">套餐价格</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="price" value="${data.price}" placeholder="￥" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">状态</label>
                    <div class="layui-input-inline w334">
                        ${data['state'] == '1' ?
                        `
                        <input type="radio" name="state" value="0" title="禁用" >
                        <input type="radio" name="state" value="1" title="启用" checked>
                        ` :
                        `
                        <input type="radio" name="state" value="0" title="禁用" checked>
                        <input type="radio" name="state" value="1" title="启用" >
                        ` }
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">描述</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" value="${data.remark}" name="remark">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">排序</label>
                    <div class="layui-input-inline w50">
                        <input class="layui-input" type="number" value="${data.sort}" name="sort" value="0'">
                    </div>
                </div>

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '编辑套餐',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/product/setmealedit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        recharge(data){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="uid" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input layui-disabled" type="text" value="${data.username}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">充值</label>
                        <div class="layui-input-block">
                            <input type="radio" name="type" value="0" title="入款" checked>
                            <input type="radio" name="type" value="1" title="扣款">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">金额</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="number" name="money" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">备注</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="note">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '充值',
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/user/recharge.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        package_price(data){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;
                let url = '/' + module_name + '/user/package_price.html';
                yunu.get({
                    url: url,
                    field: {id: data.id},
                    success: function (res) {
                        layer.open({
                            type: 1,
                            title: '修改价格',
                            area: ['500px', '620px'],
                            content: res.tpl,
                            success: function (layero, index) {
                                let package_field = {}
                                table.init('package_price');
                                table.on('edit(package_price)', function(obj){
                                    if (obj.value) {
                                        if (obj.value.match(/^([1-9]\d*|[0]{1})(\.\d{1,2})?$/) == null) {
                                            yunu.msg('请输入正确金额', 'error');
                                            return false;
                                        }
                                    };
                                    console.log(obj);
                                    var param = {
                                        'sid': obj['data']['sid'],
                                        'price': obj.value,
                                        'uid': data.id,
                                    }
                                    $.post(url, param, function (res) {
                                        yunu.msg(res.info || '错误', res.state == 1 ? null : 'error');
                                    });
                                });
                            }
                        });
                    }
                });
            });
        },
        admin_add(){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">账号</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="username" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">密码</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="password" name="password" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '新增管理员',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/admin/add.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        admin_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">账号</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input layui-disabled" type="text" value="${data.username}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">新密码</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="password" name="password">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '编辑管理员',
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/admin/edit.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        review(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">公司名称：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.cname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">店铺ID：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.xzhid}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">联系人：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.contacts}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">手机号：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.tel}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">行业关键词：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.keywords}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">备注：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.remark != null ? data.remark : ''}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">账号</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.account}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">密码</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.password}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">购买套餐：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.setmealtitle}</div>
                            </div>
                        </div>
                    </div>
                    ${data['state'] != 1 ?
                    `<div class="layui-form-item">
                         <label class="layui-form-label">审核：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="state" value="1" title="通过" ${data.state == 1 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                             <input type="radio" name="state" value="2" title="不通过" ${data.state == 2 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                         </div>
                     </div>` : ''}
                    <div class="layui-form-item" id="review_note" style="display: none;">
                        <label class="layui-form-label"></label>
                        <div class="layui-input-block mr50">
                            <textarea name="review_note" class="layui-textarea"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        layer.photos({
                            photos: '.photos'
                        });
                        form.on('radio(state)', function(data){
                            data.value == 2 ? $('#review_note').show() : $('#review_note').hide();
                        });
                        form.on('submit(*)', function(data){
                            if (!data.field.state) {
                                yunu.msg('请审核', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/qcy/review.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                    $(obj.tr).find('.table_btn').remove();
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        reviewkey(data, obj, callback){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 pr20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">网址：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" name="domain" value="http://www.${data.domain}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">熊掌号：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" name="xzh" value="${data.xzh != null ? data.xzh : ''}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">关键词：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" name="keyword" value="${data.keyword}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">添加时间：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" value="${data.add_time_format}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">百度指数：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" value="${data.baidu_index}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">初排：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input idis" type="text" value="${data.initial_rank}" disabled>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">价格：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="price" value="${data.price}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                         <label class="layui-form-label">审核：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="state" value="1" title="通过" required lay-verify="required" checked>
                             <input type="radio" name="state" value="2" title="删除" required lay-verify="required">
                         </div>
                     </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: [],
                    content: tpl({
                        ...data
                    }),
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            if (!data.field.state) {
                                yunu.msg('请审核', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/yunkuaipai/ok.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            if (typeof callback == 'function') {
                                                callback(res);
                                            } else {
                                                window.location.reload();
                                            }
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });

            });
        },
        order_price_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">价格</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="price" value="${data.price}">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '批量调价',
                    area: ['580px'],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/yunkuaipai/editprice.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(res.data);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        sync(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="account" value="${data.account}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">密码：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="password" value="${data.password}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">联系方式：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.tel}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司名称：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.cname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">法人代表：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.legal_name}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业分类：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.type}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司地址：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.address}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">官网Url：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.domain_url}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline photo_box">
                            <label class="layui-form-label">公司logo：</label>
                            <div class="layui-input-inline">
                                <img layer-src="${data.logo}" src="${data.logo}">
                            </div>
                        </div>
                        <div class="layui-inline photo_box">
                            <label class="layui-form-label">营业指摘：</label>
                            <div class="layui-input-inline">
                                <img layer-src="${data.license}" src="${data.license}">
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业关键词：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.keywords}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">备注：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.note}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">购买套餐：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.package_name}</div>
                                <input type="hidden" name="package_name" value="${data.package_name}" />
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">参与活动：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.activity_name != null ? data.activity_name : '无'}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                         <label class="layui-form-label">套餐：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="comboId" value="13" title="模板月度" ${data.package_id == 1 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="10" title="模板季度" ${data.package_id == 2 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="11" title="模板半年" ${data.package_id == 3 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="12" title="模板年度" ${data.package_id == 4 ? 'checked' : ''}>
                             <br>
                             <input type="radio" name="comboId" value="15" title="官网月度" ${data.package_id == 5 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="4" title="官网季度" ${data.package_id == 6 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="5" title="官网半年" ${data.package_id == 7 ? 'checked' : ''}>
                             <input type="radio" name="comboId" value="6" title="官网年度" ${data.package_id == 8 ? 'checked' : ''}>
                         </div>
                     </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                            <button type="reset" class="layui-btn layui-btn-primary">取消</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        layer.photos({
                            photos: '.photos'
                        });
                        form.on('submit(*)', function(data){
                            if (!data.field.comboId) {
                                yunu.msg('请选择套餐', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/Review/sync',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info);
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        packageshow(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">公司名称：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.cname}</div>
                            </div>
                        </div>
                    </div>
                    ${data['proname'] != '其他类型' ?
                    `
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">建站产品：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.proname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">产品类型：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.protype}</div>
                            </div>
                        </div>
                    </div>
                        ${data['proname'] == '模板网站' ?
                        `
                        <div class="layui-form-item">
                            <div class="layui-inline">
                                <label class="layui-form-label">模板编号：</label>
                                <div class="layui-input-inline">
                                    <div class="layui-form-mid">${data.template_number}</div>
                                </div>
                            </div>
                        </div>
                        ` : ''}
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">空间：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.fujia1}</div>
                            </div>
                        </div>

                         ${data['proname'] == 'CMS建站' ?
                        `
                        <div class="layui-inline">
                            <label class="layui-form-label">域名：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.domain}</div>
                            </div>
                        </div>
                        `:`
                        <div class="layui-inline">
                            <label class="layui-form-label">小程序：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.fujia2}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">英文版：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.fujia3}</div>
                            </div>
                        </div>
                        `}
                    </div>

                    ` : `
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">建站产品：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.proname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label"></label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid"></div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label"></label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid"></div>
                            </div>
                        </div>
                    </div>

                    `}
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">订单描述：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.content}</div>
                            </div>
                        </div>
                    </div>

                    <div class="layui-form-item">
                         <label class="layui-form-label">制作状态：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="makestate" value="1" title="已交付" ${data.makestate == 1 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                             <input type="radio" name="makestate" value="2" title="制作中" ${data.makestate == 2 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                         </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '查看',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        layer.photos({
                            photos: '.photos'
                        });
                        form.on('submit(*)', function(data){
                            if (!data.field.makestate) {
                                yunu.msg('请选择订单状态', 'error');
                                return false;
                            }
                            yunu.post({
                                url: '/' + module_name + '/Package/review',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        activity_add(){
            let tpl = `<form class="layui-form pt25 pb20" lay-filter="form">
                <div class="form_group_box"></div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确认添加</button>
                        <a class="color ml20 add_items" href="javascript:;">增加活动</a>
                    </div>
                </div>
            </form>`;

            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;
                let laydate = layui.laydate;

                let add_items = function () {
                    let index = $('.form_group_box .layui-form-item').length;
                    $('.form_group_box').append(`
                        <div class="form_group_item">
                            <div class="layui-form-item">
                                <label class="layui-form-label">活动名称：</label>
                                <div class="layui-input-inline w334">
                                    <input class="layui-input" type="text" name="name[]" required lay-verify="required">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">活动时间：</label>
                                <div class="layui-input-inline w334">
                                    <input type="text" class="layui-input" id="date_range_${index}" name="date_range[]" required lay-verify="required">
                                </div>
                            </div>
                            <div class="layui-form-item">
                                <label class="layui-form-label">选择套餐：</label>
                                <div class="layui-input-inline w334">
                                    <select name="package_id[]" xm-select="package_id_${index}" xm-select-search="" xm-select-radio required lay-verify="required">
                                        <option value="">请选择</option>
                                    </select>
                                </div>
                            </div>
                            <div class="layui-form-item" lay-filter="form_select2_${index}">
                                <label class="layui-form-label">购买年限：</label>
                                <div class="layui-input-inline w334">
                                    <select name="year[]" required lay-verify="required">
                                        <option value="1">一年</option>
                                        <option value="2">二年</option>
                                        <option value="3">三年</option>
                                    </select>
                                </div>
                            </div>
                            <div class="layui-form-item" lay-filter="form_select_${index}">
                                <label class="layui-form-label">赠送时长：</label>
                                <div class="layui-input-inline w334">
                                    <select name="gift_month[]" required lay-verify="required">
                                        <option value="1">送1个月</option>
                                        <option value="3">送3个月</option>
                                        <option value="6">送6个月</option>
                                        <option value="12">送12个月</option>
                                        <option value="24">送24个月</option>
                                    </select>
                                </div>
                            </div>
                        </div>
                    `);
                    layui.formSelects.data('package_id_'+index, 'server', {
                        url: '/' + module_name + '/Api/get_package'
                    });
                    laydate.render({
                        elem: '#date_range_'+index
                        ,type: 'datetime'
                        ,range: true
                    });
                    form.render('select', 'form"] .layui-form-item[lay-filter="form_select_'+index);
                    form.render('select', 'form"] .layui-form-item[lay-filter="form_select2_'+index);
                }

                layer.open({
                    type: 1,
                    title: '添加',
                    area: ['631px', '60%'],
                    content: tpl,
                    success: function (layero, index) {
                        add_items();
                        $('.add_items').on('click', add_items);
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Activity/add',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            window.location.reload();
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        activity_edit(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20" lay-filter="form">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">活动名称：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="name" value="${data.name}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">活动时间：</label>
                        <div class="layui-input-inline w334">
                            <input type="text" class="layui-input" id="date_range" name="date_range" value="${data.starttime+' - '+ data.endtime}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">选择套餐：</label>
                        <div class="layui-input-inline w334">
                            <select name="package_id" xm-select="package_id" xm-select-search="" xm-select-radio required lay-verify="required">
                                <option value="">请选择</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item" lay-filter="form_select2">
                        <label class="layui-form-label">购买年限：</label>
                        <div class="layui-input-inline w334">
                            <select name="year[]" required lay-verify="required">
                                <option value="1" ${data.year == 1 ? 'selected' : ''}>一年</option>
                                <option value="2" ${data.year == 2 ? 'selected' : ''}>二年</option>
                                <option value="3" ${data.year == 3 ? 'selected' : ''}>三年</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item" lay-filter="form_select">
                        <label class="layui-form-label">赠送时长：</label>
                        <div class="layui-input-inline w334">
                            <select name="gift_month" required lay-verify="required">
                                <option value="1" ${data.gift_month == 1 ? 'selected' : ''}>送1个月</option>
                                <option value="3" ${data.gift_month == 3 ? 'selected' : ''}>送3个月</option>
                                <option value="6" ${data.gift_month == 6 ? 'selected' : ''}>送6个月</option>
                                <option value="12" ${data.gift_month == 12 ? 'selected' : ''}>送12个月</option>
                                <option value="24" ${data.gift_month == 24 ? 'selected' : ''}>送24个月</option>
                            </select>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确认</button>
                        </div>
                    </div>
                </form>`
            };

            if (!layui.hasOwnProperty('formSelects')) {
                layui.config({
                    base: '/Public/Public/js/'
                }).extend({
                    formSelects: 'formSelects-v4.min'
                });
            }

            layui.use(['layer', 'form', 'formSelects', 'laydate'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let formSelects = layui.formSelects;
                let laydate = layui.laydate;

                layer.open({
                    skin: 'no_scroll',
                    type: 1,
                    title: '添加',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        formSelects.config('package_id', {
                            beforeSuccess: function(id, url, searchVal, result){
                                for (var i = 0; i < result.data.length; i++) {
                                    if (result.data[i]['value'] == data.package_id) {
                                        result.data[i]['selected'] = 'selected';
                                    }
                                }
                                return result;
                            },
                        });
                        layui.formSelects.data('package_id', 'server', {
                            url: '/' + module_name + '/Api/get_package'
                        });
                        laydate.render({
                            elem: '#date_range'
                            ,type: 'datetime'
                            ,range: true
                        });
                        form.render();
                        form.on('submit(*)', function(data){
                            let [starttime, endtime] = data.field.date_range.split(' - ');
                            data.field.starttime = starttime;
                            data.field.endtime = endtime;
                            yunu.post({
                                url: '/' + module_name + '/Activity/edit',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        reviewDouyin(data, obj){
            let tpl = (data = {}) => {
                return `<form class="layui-form pt25 pb20 photos">
                    <input type="hidden" name="id" value="${data.id}" />
                    <div class="layui-form-item">
                        <label class="layui-form-label">登录账号：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="account" value="${data.account}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <label class="layui-form-label">密码：</label>
                        <div class="layui-input-inline w334">
                            <input class="layui-input" type="text" name="password" value="${data.password}" required lay-verify="required">
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">联系方式：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.tel}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司名称：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.cname}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">法人代表：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.legal_name}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业分类：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.type}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">公司地址：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.address}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline photo_box">
                            <label class="layui-form-label">营业执照：</label>
                            <div class="layui-input-inline">
                                <img layer-src="${data.license}" src="${data.license}" width="180px" height="150px">
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">行业关键词：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.keywords}</div>
                            </div>
                        </div>
                        <div class="layui-inline">
                            <label class="layui-form-label">备注：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.remark}</div>
                            </div>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-inline">
                            <label class="layui-form-label">购买套餐：</label>
                            <div class="layui-input-inline">
                                <div class="layui-form-mid">${data.package_name}</div>
                            </div>
                        </div>
                    </div>
                    ${data['state'] != 1 ?
                    `<div class="layui-form-item">
                         <label class="layui-form-label">审核：</label>
                         <div class="layui-input-block">
                             <input type="radio" name="state" value="1" title="通过" ${data.state == 1 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                             <input type="radio" name="state" value="2" title="不通过" ${data.state == 2 ? 'checked' : ''} required lay-verify="required" lay-filter="state">
                         </div>
                     </div>` : ''}
                    <div class="layui-form-item" id="review_note" style="display: none;">
                        <label class="layui-form-label"></label>
                        <div class="layui-input-block mr50">
                            <textarea name="review_note" class="layui-textarea"></textarea>
                        </div>
                    </div>
                    <div class="layui-form-item">
                        <div class="layui-input-block">
                            <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                        </div>
                    </div>
                </form>`;
            };

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '审核',
                    area: [],
                    content: tpl(data),
                    success: function (layero, index) {
                        form.render();
                        layer.photos({
                            photos: '.photos'
                        });
                        form.on('radio(state)', function(data){
                            data.value == 2 ? $('#review_note').show() : $('#review_note').hide();
                        });
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Douyin/review.html',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            obj.update(data.field);
                                        }
                                    });
                                    $(obj.tr).find('.table_btn').remove();
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        cluewords_relation(data, obj, api_url){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '关键词',
                    area: ['1200px', '60%'],
                    content: `<div class="pd20">
                        <div class="top-tip mb20">
                            列表只出现已审核的关键词，代理商可以根据需求自行维护客户数据，每个客户每天最多手动采集5次，请认真核对数据后再进行提交<a href="https://docs.qq.com/doc/DUmVtVWt5R1lqaHhK" target="_blank">【查看教程】</a>
                        </div>
                        <div class="table_mult">
                            <div class="table_cluewords_relation" id="table_cluewords_relation" lay-filter="table_cluewords_relation"></div>
                        </div>
                    </div>`,
                    success: function (layero, index) {
                        yunu.table({
                            elem: '#table_cluewords_relation',
                            skin: 'mult',
                            url: api_url,
                            where: {id: data.id},
                            limits: [10, 20, 50, 100, 200],
                            cols: [[
                                {field: 'index', title: '序号', type: 'numbers', width:60, sort: true}
                                ,{field: 'keyword', title: '关键词', align: 'center'}
                                ,{field: 'review_state', title: '审核状态', align: 'center', templet: function (d) {
                                    if (d.review_state == 1) {
                                        return '已审核'
                                    }
                                    return '待审核';
                                }}
                                ,{title: '操作', align: 'center', minWidth: 400, templet: function (d) {
                                    return '<a class="table_btn" href="javascript:;" lay-event="douyin_single">单采</a>';
                                }}
                            ]],
                        }, {
                            table: 'table_cluewords_relation',
                            search: false,
                            qsave: '/' + module_name + '/Douyin/cluewords_relation_qsave',
                            switch: ['state', 'precise_mode'],
                            event: {
                                douyin_single: function (data, obj) {
                                    yunu.douyin_single(data, obj);
                                },
                            }
                        });
                    }
                })
            });
        },
        douyin_single(data, obj){
            let tpl = `<form class="layui-form pt25 pb20">
                <input type="hidden" name="rid" value="${data.id}" />
                <div class="layui-form-item">
                    <label class="layui-form-label">视频id</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="aweme_id" required lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">命中关键词</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="keywords" required lay-verify="required" value="多少钱|价格|方案|联系|电话|怎么买|怎么卖|合作|购买|地址|在哪里|多少米|哪买的|有兴趣了解">
                    </div>
                </div>
                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;

                layer.open({
                    type: 1,
                    title: '单个视频采集',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function(data){
                            yunu.post({
                                url: '/' + module_name + '/Douyin/single',
                                field: data.field,
                                success: function (res) {
                                    layer.close(index);
                                    layer.alert(res.info);
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        staff_editor(data, obj){
            let tpl = `<form class="layui-form pt25 pb20">
                <div class="layui-form-item">
                    <label class="layui-form-label">员工姓名</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="text" name="name" value="${data?.name ?? ''}" lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">员工手机号</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input tel" type="text" name="tel" value="${data?.tel ?? ''}" lay-verify="required">
                    </div>
                </div>
                <div class="layui-form-item">
                    <label class="layui-form-label">密码</label>
                    <div class="layui-input-inline w334">
                        <input class="layui-input" type="password" name="password" value="${data?.password ?? ''}" lay-verify="required">
                    </div>
                </div>

                <div class="layui-form-item">
                    <div class="layui-input-block">
                        <button class="layui-btn" lay-submit lay-filter="*">确定</button>
                    </div>
                </div>
            </form>`;

            layui.use(['layer', 'form'], function(){
                let layer = layui.layer;
                let form = layui.form;
                layer.open({
                    type: 1,
                    title: '新增',
                    area: ['580px'],
                    content: tpl,
                    success: function (layero, index) {
                        form.render();
                        form.on('submit(*)', function({ field }){
                            if (data) {
                                field.id = data.id;
                            }
                            yunu.post({
                                url: '/' + module_name + '/staff/editor',
                                field,
                                success: function (res) {
                                    layer.close(index);
                                    yunu.msg(res.info, {
                                        end: function () {
                                            if (data) {
                                                obj.update(field);
                                            } else {
                                                window.location.reload();
                                            }
                                        }
                                    });
                                }
                            });
                            return false;
                        });
                    }
                });
            });
        },
        keywords_table(data, obj){
            layui.use(['layer', 'form', 'table'], function(){
                let layer = layui.layer;
                let form = layui.form;
                let table = layui.table;

                layer.open({
                    type: 1,
                    title: '关键词',
                    area: ['640px', '60%'],
                    content: `<div class="pl10 pr10"><a class="layui-btn btn mt20" id="keyword_add" href="javascript:;">添加</a><div class="keywords_table" id="keywords_table" lay-filter="keywords_table"></div></div>`,
                    success: function(layero, index){
                        yunu.table({
                            url: '/' + module_name + '/Qcy/keywords_table',
                            where: {
                                pid: data.id,
                            },
                            elem: '#keywords_table',
                            cols: [[
                                {field: 'index', title: '序号', type: 'numbers', width: 100, sort: true}
                                ,{field: 'keyword', title: '关键词', align: 'center'}
                                ,{field: 'stateVal', title: '状态', align: 'center'}
                                ,{title: '操作', width: 150, align: 'center', templet: function (d) {
                                    if (d.state == 0) {
                                        return '<a class="layui-btn layui-btn-xs btn" href="javascript:;" lay-event="delete">删除</a>';
                                    }
                                    return '-';
                                }}
                            ]]
                        }, {
                            table: 'keywords_table',
                            event: {
                                delete: function (data, obj) {
                                    layer.confirm('是否确认删除', {icon: 3, title:'提示'}, function(index){
                                        layer.close(index);
                                        yunu.post({
                                            url: '/' + module_name + '/Qcy/keywords_del',
                                            field: {id: data.id},
                                            success: function (res) {
                                                yunu.msg(res.info);
                                                table.reload('keywords_table')
                                            }
                                        });
                                    });
                                }
                            },
                        });

                        $('#keyword_add').on('click', function () {
                            layer.prompt({
                                formType: 2,
                                title: '添加',
                                maxlength: 999999999,
                                success: function(layero, index){
                                    $(layero).find('.layui-layer-content').append('请输入关键词，格式为一行一个')
                                }
                            }, function(value, index, elem){
                                yunu.post({
                                    url: '/' + module_name + '/Qcy/keywords_add',
                                    field: {keyword: value, pid: data.id},
                                    success: function (res) {
                                        layer.close(index);
                                        yunu.msg(res.info);
                                        table.reload('keywords_table')
                                    }
                                });
                            });
                        });
                    }
                });
            });
        },
    });

    $(() => {

    });

})(jQuery, window, document);
